<template>
  <div >
    <el-form :model="form" label-width="80px">
      <el-form-item label="状态：">
        <el-radio-group v-model="form.gprs_status">
          <el-radio v-for="item in statusOptions" :label="item.value">{{item.label}}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="位置：">
        <el-radio-group v-model="form.position_now" @input="handlePositionChange">
          <el-radio v-for="item in positionOptions" :label="item.value">{{item.label}}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="" v-if="[1530,1531].includes(form.position_now)">
        <el-select v-if="form.position_now == 1530" v-model="form.organization_id" remote :remote-method="getHospitalOptions" filterable placeholder="请选择医院" size="medium" clearable>
          <el-option
              v-for="item in hospitalOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              :filter-method="getHospitalOptions">
          </el-option>
        </el-select>
        <el-select
            v-if="form.position_now == 1531"
            v-model="form.organization_id"
            placeholder="请输入经销商"
            size="medium"
            filterable
            clearable
            remote
            :filter-method="remoteMethod"
        >
          <el-option
              v-for="item in organizationList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="">
        <el-checkbox v-model="isChange" @change="handleChangeReplace">已更换铭牌</el-checkbox>
      </el-form-item>
      <el-form-item label="">
        <el-input v-model="form.replaced_instrument_id" v-if="isChange" size="medium">
          <span slot="prefix" >新铭牌：</span>
        </el-input>
      </el-form-item>
    </el-form>
     <span slot="footer" class="dialog-footer" style="text-align: center">
         <el-button type="primary" style="padding: 12px 45px;margin-right: 30px" size="mini" @click="save()">保存</el-button>
         <el-button  plain size="mini" style="padding: 12px 45px" @click="closeDialog()">取消</el-button>
      </span>
  </div>

</template>
<script>
import apiDetail from "@/api/searchDetail";
import {hospitals_list, search_customer} from "@/api/search";

export default {
  props:{
    formData:{
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      statusOptions:[
        {label: '正常', value: 2001},
        {label: '停用', value: 2002},
        {label: '备用机', value: 2003},
        {label: '未启用', value: 2004},
        {label: '传输失败', value: 2005}
      ],
      positionOptions:[
        {label: '医院', value: 1530},
        {label: '经销商', value: 1531},
        {label: '厂内', value: 1532},
      ],
      hospitalOptions: [],
      organizationList: [],
      isChange: false,
      form:{
        gprs_status: null,
        instrument_id: this.$route.query.id,
        organization_id: null,
        position_now:  1532,
        replaced_instrument_id: '',
      }
    }
  },
  created() {
    if(this.formData){
      this.form.gprs_status = this.formData.gprs_status
      this.form.position_now = this.formData.position_now
      if( this.formData._instrument_id){
        this.isChange = true
        this.form.replaced_instrument_id = this.formData._instrument_id
      }else{
        this.isChange = false
      }

      if(this.formData.position_now){
        if(this.form.position_now === 1530){
          this.form.organization_id = this.formData.hospital_id
          // this.getHospitalOptions(this.formData.hospital_name)
          this.hospitalOptions = [{label:this.formData.hospital_name,value:this.formData.hospital_id }]
        }else if(this.form.position_now === 1531){
          this.form.organization_id = this.formData.customer_id
          // this.remoteMethod(this.formData.customer_name)
          this.organizationList = [{label:this.formData.customer_name,value:this.formData.customer_id }]
        }else if(this.form.position_now === 1532){
          this.form.organization_id = 10000
        }
      }else{
        if(this.formData.hospital_id){
          this.form.position_now = 1530
          this.form.organization_id = this.formData.hospital_id
          this.hospitalOptions = [{label:this.formData.hospital_name,value:this.formData.hospital_id }]
        }else if(this.form.organization_id){
          this.form.position_now = 1531
          this.form.organization_id = this.formData.customer_id
          this.organizationList = [{label:this.formData.customer_name,value:this.formData.customer_id }]
        }else{
          this.form.position_now = 1532
        }
      }

    }
  },
  methods: {
    handlePositionChange(){
      if(this.form.position_now == 1532){
        this.form.organization_id = 10000
      }else if(this.form.position_now === 1530){
        if(this.formData.hospital_id === 10000){
          this.form.organization_id = ''
          this.form.organization_name = ''
        }else if(this.formData.hospital_name){
          this.form.organization_id = this.formData.hospital_id
          this.form.organization_name = this.formData.hospital_name
          this.hospitalOptions = [{label:this.formData.hospital_name,value:this.formData.hospital_id }]
        }else{
          this.form.organization_id = ''
          this.form.organization_name = ''
        }
      }else if(this.form.position_now === 1531){
        if(this.formData.customer_name){
          this.form.organization_id = this.formData.customer_id
          this.organizationList = [{label:this.formData.customer_name,value:this.formData.customer_id }]
        }else{
          this.form.organization_id = ''
          this.form.organization_name = ''
        }
      }
    },
    handleChangeReplace(val){
      if(!val){
        this.form.replaced_instrument_id = null
      }
    },
    save(){
      this.$route.query.type = ''
      if(this.form.position_now==1532){
        this.form.organization_id = 10000
      }
      if([1530,1531].includes(this.form.position_now)){
        if(!this.form.organization_id){
          this.$message.error(this.form.position_now ==1530 ? '请选择医院' : '请选择经销商')
          return
        }
      }
      if(this.isChange){
        if(!this.form.replaced_instrument_id){
          this.$message.error('请填写新铭牌')
          return
        }
      }
      if(this.form.replaced_instrument_id){
        let reg = /^.{8,9}$/
        if(!reg.test(this.form.replaced_instrument_id)){
          this.$message.error('新铭牌格式不正确')
          return
        }
      }
      apiDetail.edit_instrument(this.form).then(res => {
        if(res.code === 200){
          this.$message({
            message: '保存成功',
            type: 'success'
          })
          this.$emit('update',)
          this.closeDialog()
          sessionStorage.setItem('mode','instruments')
          // this.$router.replace({ path: `/instruments-detail?id=${this.$route.query.id}`, query: {} });
        }
      }).finally(()=>{
        this.closeDialog()
      })
    },
    closeDialog(){
      this.$emit('close',false)
    },
    getHospitalOptions(e){
      this.hospitalOptions = hospitals_list({ hospital_name: e })
    },
    remoteMethod(query) {
      this.organizationList = search_customer({ customer_name: query })
    },
  }
}
</script>
<style>

</style>
<style scoped lang="scss">
::v-deep .dialog-footer{
  display: inline-block;
  width: 100%;
  text-align: center;
}

::v-deep .el-form-item__label{
  font-weight: 300;
}
::v-deep .el-form-item{
  margin-bottom: 10px;
}
::v-deep .el-input--prefix .el-input__inner{
  padding-left: 60px;
}
::v-deep .el-input--medium .el-input__inner{
  line-height: 40px;
  height: 40px;
}
::v-deep .el-select.el-select--medium{
  width: 320px;
}
::v-deep .el-select .el-input .el-input__inner{
  width: 100%;
}

</style>